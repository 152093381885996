.school-start-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5; /* Light gray background, you can change it as needed */
  margin: 0;
  padding: 0;
}

.school-start-img {
  max-width: auto;
  height: 100%;
}

@media (max-width: 768px) {
  .school-start-container {
    background-color: #fff;
  }

  .school-start-img {
    max-width: 100%; /* Make the image larger on smaller screens */
    height: auto;
    margin-top: 25px;
  }
}
