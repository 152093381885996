.about-history {
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.history-title {
    text-align: center;
}

.history-title h1 {
    font-size: 2rem;
}

.history-main p {
    text-indent: 30px;
    text-align: justify;
}

@media screen and (max-width: 768px) {
    .about-history {
        padding: 10px;
        padding-top: 80px;
    }
    .history-title h1 {
        font-size: 1.5rem;
    }
    .history-title h2 {
        font-size: large;
    }
}