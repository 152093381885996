footer {
    background-color: #372ea4;
    color: #fff;
    padding: 20px;
    padding-bottom: 10px;
}

.footer-content {
  display: flex;
  flex-direction: row;
}

.qr-code {
  margin-left: 80px;
  align-items: center;
  text-align: center;
  font-size: 12px;
}

.footer-content img {
  width: 8vw;
}

.footer-bottom {
    font-size: 10px;
    text-align: right;
}

@media only screen and (max-width: 768px) {
  .footer-section {
    font-size: 8px;
  }
  .qr-code {
    font-size: 8px;
    margin-left: 50px;
  }
  .qr-code img {
    width: 70px;
  }
  .footer-bottom {
    font-size: 6px;
  }
  .footer-section p {
    line-height: 1;
  }
}