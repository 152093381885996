.header-top {
    position: relative;
    background-color: #63972f;
}

.LanguageSwitcher {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}

.LanguageSwitcher button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border: #63972f;
    background-color: #63972f;
    color: black;
    transition: background-color 0.3s, color 0.3s;
}

.LanguageSwitcher button:hover {
    background-color: #333;
    color: #fff;
}

.social-links {
    display: flex;
    align-items: center;
    background-color: #63972f;
}

.social-links a {
    margin-left: 10px;
}

.social-links img {
    margin-top: 3px;
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 768px) {
    .LanguageSwitcher {
        position: relative;
        display: block;
        text-align: right;
        margin-top: 10px;
    }

    .LanguageSwitcher button {
        margin-left: 10px;
    }

}