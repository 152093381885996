.parent h1 {
    text-align: center;
}

.parent h2 {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .parent {
        padding: 10px;
        padding-top: 80px;
    }
    .parent h1 {
        font-size: 1.5rem;
    }
    .parent h2 {
        font-size: large;
    }
}