.card-container {
    width: 30rem;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    background-color: white;
    height: fit-content;
    z-index: 1;
  }

.card-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
}

.card-title {
    margin: 0.5rem 5%;
    font-size: large;
}

.card-description {
    margin: 0.5rem 5%;
    font-size: medium;
}

.card-btn {
    text-align: center;
    width: 90%;
    border-radius: 5px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    background-color: #2563eb;
    color: white;
    text-decoration: none;
    margin: 0.5rem auto;
}

@media only screen and (max-width: 980px) {
    .card-container {
        width: 20rem;
        margin: 1rem;
    }
}