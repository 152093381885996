/* Header.css */

header {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clsni-logo {
        margin-left: 100px;
        width: 50%;
        max-width: 400px;
        height: auto;
}

@media only screen and (max-width: 768px) {

    header {
        position: relative;
        margin-top: -10rem;
    }

    .clsni-logo {
        display: flex;
        position: absolute;
        align-items: center;
        z-index: 1;
        padding: 5px;
    }

}
