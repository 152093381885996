.management h1 {
    text-align: center;
}

.management h2 {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .management {
        padding: 10px;
        padding-top: 80px;
    }

    .management h1 {
        font-size: 1.5rem;
    }

    .management h2 {
        font-size: large;
    }

}