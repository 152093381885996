.about-constitution {
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.constitution-title h1 {
    font-size: 2rem;
    text-align: center;
}

.about-constitution h2 {
    line-height: 1.5;
}

.about-constitution li, p {
    line-height: 1.5;
}

.about-constitution p {
    text-align: left;
}

ol {
    list-style-type: lower-alpha;
    list-style-position: outside;
}

@media screen and (max-width: 768px) {
    .about-constitution {
        padding: 10px;
        padding-top: 90px;
    }
    .constitution-title h1 {
        font-size: large;
    }
    .about-constitution h2 {
        font-size: medium;
    }
}