

.teacher h1 {
    text-align: center;
}

.teacher p {
    margin: 0 0 10%;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .teacher {
        padding-top: 80px;
    }
}