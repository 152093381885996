
.table-container {
    display: flex;
    justify-content: center;
    padding: 50px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #f5f5f5;
}

@media screen and (max-width: 768px) {
    .table-container {
        padding: 10px;
    }
}
