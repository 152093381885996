.nav {
    display: flex;
}

.navbar-toggle {
    display: none;
}

.nav-item {
    text-decoration: none;
    color: black;
    padding: 8px 16px;
    position: relative;
}

.nav-item:hover {
    background-color: #85bc4d;
    color: white;
}

.nav-item a {
    text-decoration: none;
    color: black;
}

.nav-item a:hover {
    color: white;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.nav-link {
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;

    font-size: 1rem;
}

.dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: black;
}

.dropdown-item:hover {
    background-color: #63972f;
    color: white;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media only screen and (max-width: 768px) {
    .nav-items {
        display: none;
    }

    .nav.active .nav-items {
        display: flex;
        flex-direction: column;
        z-index: 2;
        background: white;
    }

    .navbar-toggle {
        display: block;
        background-color: #372ea4;
        color: white;
        cursor: pointer;
        padding: 10px;
        border: none;
        text-align: center;
        font-size: 16px;
        z-index: 2;
    }

    .navbar-toggle:hover {
        background-color: #372ea4;
        color: white;
        transition: background-color 0.3s, color 0.3s
    }

    .dropdown-content {
        position: relative;
    }
}

